@import "react-complex-tree/lib/style.css";

:root {
    --rct-color-tree-bg: transparent;

    --rct-color-focustree-item-selected-bg: var(--rs-color-background-primary);
    --rct-color-focustree-item-focused-border: transparent;

    --rct-color-nonfocustree-item-selected-bg: var(--rs-color-background-primary);
    --rct-color-nonfocustree-item-selected-text: inherit;
    --rct-color-nonfocustree-item-focused-border: transparent;
    --rct-item-height: 22px;
    --rct-color-tree-focus-outline: inherit;

    /*
    Fallback value. Actual value is provided by react-complex-tree on the element itself.
     */
    --depthOffset: 0px;
}

.rct-tree-root {
    font-family: inherit;
}

.rct-tree-item-title-container {
    padding-left: 0;
    margin-top: 4px;
    margin-bottom: 4px;
}


.rct-tree-item-title-container .rct-tree-item-button {
    /* Adjust indent padding. Each item has a basic padding of 4px.
    And we need to reduce each padding by 10px to make items look like in the specs.
    */
    padding-left: calc(var(--depthOffset, 0px) + 4px);
}

.rct-tree-item-li {
    font-size: var(--rs-font-size-body-2);
}

.rct-tree-item-title-container-focused {
    background-color: var(--rs-color-background-neutral);
}

.rct-tree-item-title-container:not(.rct-tree-item-title-container-focused):not(.rct-tree-item-title-container-selected):hover {
    background-color: var(--rs-color-background-neutral);
}

.monaco-editor .find-widget {
    background-color: #151D37 !important;
}

.monaco-editor .find-widget .vertical {
    box-shadow: 2px 0 0 0 var(--rs-color-border-primary) inset !important;
}

.monaco-editor .find-widget .monaco-inputbox {
    background-color: #202943 !important;
}

.monaco-editor .find-widget textarea {
    color: var(--rs-color-white) !important;
}

.monaco-editor .find-widget textarea::placeholder,
.monaco-editor .find-widget .controls,
.monaco-editor .find-widget .find-actions,
.monaco-editor .find-widget > .button.codicon-widget-close {
    color: var(--rs-color-foreground-neutral-faded) !important;
}

.monaco-editor .find-widget .matchesCount {
    color: var(--rs-color-white) !important;
}

.monaco-editor .find-widget.no-results .matchesCount {
    color: var(--rs-color-foreground-warning) !important;
}

.monaco-editor-overlaymessage .message {
    background-color: #1F2942 !important;
    font-size: var(--rs-font-size-body-3) !important;
    font-weight: var(--rs-font-weight-body-3) !important;
    line-height: var(--rs-line-height-body-3) !important;
    color: var(--rs-color-foreground-neutral) !important;
}
